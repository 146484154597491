export const MENU_ITEMS = {
  user: {
    myAccount: { routeName: "my-account", text: "My Account" },
    myProjects: { routeName: "my-projects", text: "My Projects" }
  },
  organization: {
    generalSettings: { routeName: "general-organization-settings", text: "General Settings" },
    users: { routeName: "users", text: "Users" },
    projects: { routeName: "projects", text: "Projects" },
    permissionGroups: { routeName: "permission-groups", text: "Permission Groups" },
    activeProbes: { routeName: "organization-active-probes", text: "Active Probes" },
    chartTemplates: { routeName: "chart-templates", text: "Chart Templates" },
    reportTemplates: { routeName: "report-templates", text: "Report Templates" },
    explorers: { routeName: "explorers", text: "Explorers" },
    devicePools: { routeName: "device-pools", text: "Device Pools" },
    sims: { routeName: "sims", text: "SIMs" },
    spreadsheet: { routeName: "organization-spreadsheet", text: "Spreadsheet (ORG)" },
  },
  project: {
    connectors: { routeName: "connectors", text: "Connectors" },
    apiDocs: { routeName: "api-documentation", text: "API Documentation" },
    spreadsheet: { routeName: "project-spreadsheet", text: "Spreadsheet (PRJ)" },
  }
};

export const ROLE_GROUPS = {
  SUPER_ADMIN: ["super_admin"],
  FIRST: [1],
  SECOND: [2],
  THIRD: [3],
  FOURTH: [4, 5],
}

export const ROLE_CONFIGS = new Map([
  // Roles [3, 4, 5]
  [ROLE_GROUPS.FOURTH, {
    user: ["myAccount"],
    organization: ["explorers"],
    project: []
  }],

  // Roles [3]
  [ROLE_GROUPS.THIRD, {
    user: ["myAccount", "myProjects"],
    organization: ["explorers"],
    project: []
  }],

  // Role 2
  [ROLE_GROUPS.SECOND, {
    user: ["myAccount", "myProjects"],
    organization: ["permissionGroups", "chartTemplates", "reportTemplates", "explorers", "spreadsheet"],
    project: ["connectors", "apiDocs", "spreadsheet"]
  }],

  // Role 1
  [ROLE_GROUPS.FIRST, {
    user: ["myAccount", "myProjects"],
    organization: [
      "generalSettings", "users", "projects", "permissionGroups",
      "activeProbes", "chartTemplates", "reportTemplates", "explorers",
      "spreadsheet"
    ],
    project: ["connectors", "apiDocs", "spreadsheet"]
  }],

  // Super Admin (default)
  [ROLE_GROUPS.SUPER_ADMIN, {
    user: ["myAccount", "myProjects"],
    organization: [
      "generalSettings", "users", "projects", "permissionGroups",
      "activeProbes", "chartTemplates", "reportTemplates", "explorers",
      "devicePools", "sims", "spreadsheet",
    ],
    project: ["connectors", "apiDocs", "spreadsheet"]
  }]
]);
