import { ref, computed } from "vue";

export const isNewVariable = (_v) => !_v.id;

export function useVariable(variable) {
  const _variableName = ref(variable.name);
  const _variableValue = ref(variable.value);
  const isDirty = ref(false);

  const variableName = computed({
    get() {
      return _variableName.value;
    },
    set(val) {
      if (val === _variableName.value) {
        return;
      }
      isDirty.value = true;
      _variableName.value = val;
    }
  });

  const variableValue = computed({
    get() {
      return _variableValue.value;
    },
    set(val) {
      if (val === _variableValue.value) {
        return;
      }
      isDirty.value = true;
      _variableValue.value = val;
    }
  });


  const getUpdatedVariable = () => {
    // eslint-disable-next-line no-undef
    const _v = structuredClone(variable);
    _v.name = variableName.value;
    _v.value = variableValue.value;
    if (_v.id === '') {
      delete _v.id;
    }
    return _v;
  };

  const isValidName = (name) => name && name.length > 0;
  const isValidVariable = (_v) => isValidName(_v.name)

  const variableNameRules = [
    name => isValidName(name),
    name => name.length <= 255,
  ];

  return {
    isValidVariable,
    variableName,
    variableValue,
    getUpdatedVariable,
    variableNameRules,
    isDirty,
  }
}
