<template>
  <tr class="variable-row">
    <slot name="rowStart" />

    <td class="variable-row__cell variable-row__cell--var-name">
      <div class="variable-row__cell-inner variable-row__cell-inner--var-name">
        <slot name="firstCellPrepand" />

        <span
          v-if="!variable.id || isDirty"
          class="variable-row__status-dot"
          title="Unsaved changes"
        />

        <v-text-field
          ref="varNameField"
          class="variable-row__key-field"
          v-model="variableName"
          :rules="variableNameRules"
          outlined
          dense
          hide-details="auto"
          :background-color="isKeyEditing ? '' : '#f6f6f6'"
          @focus="() => doEditing(true, 'varNameField')"
          @blur="() => doEditing(false)"
          :error="isError"
        />
      </div>
    </td>

    <td class="variable-row__cell">
      <div class="variable-row__cell-inner variable-row__cell-inner--gap">
        <v-textarea
          ref="varValueArea"
          v-model="variableValue"
          rows="1"
          outlined
          dense
          hide-details="auto"
          :no-resize="isJsonMode"
          :background-color="isValueEditing ? '' : '#f6f6f6'"
          @focus.stop="(e) => handleVarValueFocus(e)"
          @blur="() => doEditing(false)"
        />

        <slot name="helpBtn" />

        <XCheckbox
          v-model="isJsonMode"
          label="JSON"
        />
      </div>
    </td>

    <td class="variable-row__cell">
      <div class="variable-row__cell-inner">
        <XBtn
          title="Save variable"
          icon="mdi-content-save"
          color="save"
          @click="() => save()"
          :loading="isSaving"
          :disabled="!isDirty"
        />

        <XBtn
          title="Delete variable"
          icon="mdi-delete"
          color="red"
          @click="() => deleteItem(variable)"
          :loading="isDeleting"
        />
      </div>
    </td>

    <slot name="rowEnd" />
  </tr>
</template>

<script>
import { defineComponent, ref, shallowRef, computed, onMounted, inject } from "vue";
import { useVariable, isNewVariable } from "@/composition/variables/use-variable";
import XBtn from "@/components/basic/XBtn.vue";
import XCheckbox from "@/components/basic/XCheckbox.vue";
import { useNotifications } from "@/composition/notifications";

export default defineComponent({
  name: "VariableRow",

  components: {
    XBtn,
    XCheckbox,
  },

  props: {
    variable: {
      type: Object,
      required: true,
    },
    newVariableId: {
      type: Number,
      default: -1,
    },
  },

  setup(props) {
    const varNameField = shallowRef(null);
    const varValueArea = shallowRef(null);
    const isEditing = ref(false);
    const editingWhat = ref("");
    const {
      variableName,
      variableValue,
      getUpdatedVariable,
      variableNameRules,
      isValidVariable,
      isDirty,
    } = useVariable(props.variable);
    const isSaving = ref(false);
    const isDeleting = ref(false);
    const isError = ref(false);
    const { showNotification } = useNotifications();
    const { openDialog } = inject("DialogsRoot");

    const openYesNoDialog = (_varName) =>
      openDialog("YesNoDialog2", { title: "Really delete?", question: `Do you really want to delete variable ${_varName}?` }, { isUiDialog: true });

    const isJsonValue = computed(() => {
      try {
        if (!variableValue.value) return false;
        const trimmed = variableValue.value.trim();
        return trimmed.startsWith("{") && trimmed.endsWith("}");
      } catch {
        return false;
      }
    });

    const isJsonMode = ref(isJsonValue.value);

    const {
      updateVariable,
      deleteVariable,
      hasUnsavedChanges,
    } = inject("SPREADSHEET_VIEW");

    const resetTextareaHeight = () => {
      const textarea = varValueArea.value?.$el?.querySelector?.("textarea");
      textarea?.setAttribute("style", "");
    };

    const save = async () => {
      const _variable = getUpdatedVariable();

      if (!isValidVariable(_variable)) {
        isError.value = true;
        return;
      }

      isEditing.value = false;
      isError.value = false;
      isSaving.value = true;

      try {
        const isNewVar = isNewVariable(_variable)
        await updateVariable(_variable);
        hasUnsavedChanges.value = false;
        showNotification({
          message: `Variable ${_variable.name} is ${isNewVar ? 'saved' : 'updated'}`
        });
      } catch (err) {
        if (err.name === "DuplicationError") {
          isError.value = true;
          showNotification({ message: err.message, length: 3500 })
          return;
        }
        showNotification({ message: "An error occured", length: 3500 })
        throw err;
      } finally {
        isDirty.value = false;
        isSaving.value = false;
        resetTextareaHeight();
      }
    };

    const isValueEditing = computed(
      () => isEditing.value && editingWhat.value === "varValueArea"
    );
    const isKeyEditing = computed(
      () => isEditing.value && editingWhat.value === "varNameField"
    );

    const doEditing = async (_isEditing, what = "") => {
      if (what === "varValueArea" && isJsonMode.value) {
        const result = await openDialog("JsonEditorDialog", { jsonIn: variableValue.value }, { isUiDialog: true });

        if (result?.isNew) {
          variableValue.value = result.jsonOut;
          hasUnsavedChanges.value = true;
        }
      } else {
        isEditing.value = _isEditing;
        editingWhat.value = what;
        if (_isEditing) {
          hasUnsavedChanges.value = true;
        }
      }
    };

    const handleVarValueFocus = async (event) => {
      if (isJsonMode.value) {
        event.preventDefault();
      }

      doEditing(true, "varValueArea");
    };

    const deleteItem = async (_variable) => {
      const varName = _variable.name;

      if (!varName && !_variable.value) {
        // empty variable is deleted without asking
        await deleteVariable(_variable);
        return;
      }

      const { isYes } = await openYesNoDialog(varName);
      if (!isYes) {
        return;
      }

      isEditing.value = false;
      isError.value = false;
      isDeleting.value = true;

      try {
        await deleteVariable(_variable);
        showNotification({
          message: `Variable ${varName} deleted successfully`,
        });
      } catch(err) {
        showNotification({
          message: "Failed to delete variable",
          length: 3500
        });
        throw err;
      } finally {
        isDirty.value = false;
        isDeleting.value = false;
      }
    };

    onMounted(async () => {
      if (variableName.value === "") {
        const input = varNameField.value.$el.querySelector("input");
        input.focus();
      } else if (props.newVariableId
        && props.newVariableId === props.variable.id
        && variableValue.value === "") {
        const textarea = varValueArea.value.$el.querySelector("textarea");
        textarea.focus();
      }
    });

    return {
      isSaving,
      isDeleting,
      isError,
      isEditing,
      isDirty,
      isKeyEditing,
      isValueEditing,
      isJsonMode,
      doEditing,
      variableName,
      variableValue,
      variableNameRules,
      varValueArea,
      varNameField,
      handleVarValueFocus,
      deleteItem,
      save,
    };
  },
});
</script>

<style lang="scss">
.variable-row {
  $root: &;

  &__status-dot {
    position: absolute;
    top: 50%;
    left: -13px;
    transform: translateY(-50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--v-primary-base);
    display: inline-block;
    margin-right: 8px;
  }

  &__cell {
    padding: 0.25rem 0.5rem !important;
    height: 100% !important;
    vertical-align: top !important;

    &#{$root}__cell--var-name {
      position: relative;
      width: 364px;
    }
  }

  &__cell-inner {
    display: flex;
    height: 100%;

    &#{$root}__cell-inner--gap {
      gap: 1rem;
    }

    &#{$root}__cell-inner--var-name {
      display: inline-block;
      width: 100%;
    }
  }
}
</style>
